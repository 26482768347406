.home-modal .modal-content {
    background-color: transparent;
    border: none;
}

.home-modal .modal-header {
    background-color: transparent;
    border: none;
    padding-right: 10px !important;
}

.home-modal .modal-header .btn-close {
    background: #0076BD url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23ffffff%27%3e%3cpath d=%27M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z%27/%3e%3c/svg%3e") center/1em auto no-repeat !important;
    border-radius: 50%;
}

.home-modal .home-modal-Image-slider {
    border-radius: 10px !important;
    overflow: hidden;
}

.home-modal .home-modal-Image-slider img {
    border-radius: 10px !important;
}

.home-modal img {
    width: 100%;
}
.home-modal video {
    border-radius: 10px;
}

.slick-prev {
    left: -55px !important;
    top: 45% !important;
}

.slick-next {
    right: -26px !important;
    top: 45% !important;
}

.modal-backdrop.show {
    opacity: 0 !important;
}

/* responsiveness */

@media screen and (max-width: 768px) {
    .slick-prev {
        left: 0px !important;
        top: 106% !important;
    }

    .slick-next {
        left: 80px !important;
        top: 106% !important;
    }
}