.mandatory_list:nth-child(1){
    border: 2px solid black;
    
    background-color: #0076BD;
    color: white;
}
.mandatory_list:nth-child(2){
    border-bottom: 2px solid black;
    border-left: 2px solid black;
    border-right: 2px solid black;
}
.mandatory_list:nth-child(3){
    border-bottom: 2px solid black;
    border-left: 2px solid black;
    border-right: 2px solid black;
}
.mandatory_list:nth-child(4){
    border-bottom: 2px solid black;
    border-left: 2px solid black;
    border-right: 2px solid black;
}
.mandatory_list:nth-child(5){
    border-bottom: 2px solid black;
    border-left: 2px solid black;
    border-right: 2px solid black;
}
.mandatory_list:nth-child(6){
    border-bottom: 2px solid black;
    border-left: 2px solid black;
    border-right: 2px solid black;
}
.mandatory_list:nth-child(7){
    border-bottom: 2px solid black;
    border-left: 2px solid black;
    border-right: 2px solid black;
}
.mandatory_list:nth-child(8){
    border-bottom: 2px solid black;
    border-left: 2px solid black;
    border-right: 2px solid black;
}
.mandatory_list:nth-child(9){
    border-bottom: 2px solid black;
    border-left: 2px solid black;
    border-right: 2px solid black;
}
.man_button{
    background-color: white;
    border: white;
    color: #0076BD;
    box-shadow: none;
}
.man_button:hover{
    background-color: white;
    border: white;
    color: #0076BD;
    box-shadow: none;
}
.man_button:focus{
    background-color: white;
    border: white;
    color: #0076BD;
    box-shadow: none;
}
.button_col{
    display: flex;
    align-items: center;
    justify-content: center;
}
.mandatory_table{
    margin-top: 7px;
    margin-left: 125px;
}
.mandatory_col{
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    border-right: 2px solid black;
    border-bottom: none;
}
.mandatory_col_last{
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
}
.mandatory_heading{
    margin-top: 34px;
    margin-left: 125px;
}
@media(max-width: 640px){
    .mandatory_table{
        margin-left: 0px;
        margin-top: 7px;
        display: flex;
        align-items: center;
    }
    .mandatory_col{
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-right: none;
    }
    .mandatory_col_{
        display: flex;
        flex-direction: column;
        text-align: center;
        border-right: none;
    }
    .mandatory_col_last{
        display: flex;
        flex-direction: column;
        flex-direction: column;
        justify-content: center;
        border-right: none;
        
    }
    .mandatory_heading{
        display: flex;
        justify-content: center;
        margin-top: 20px;
        margin-left: 0px;
    }
}